<template>
  <div class="pa-3">
    <v-form ref="form" lazy-validation>
      <v-dialog
        v-model="showForm"
        max-width="1300px"
        persistent
        transition="dialog-top-transition"
        @keydown.esc="close"
      >
        <v-card>
          <v-card-title>
            <span class="headline">
              {{
                editedItem.id > 0
                  ? "Corrosion Test # " +
                    (editedItem.code ? editedItem.code : "")
                  : "New Corrosion Test"
              }}</span
            >

            <v-spacer></v-spacer>
            {{ editedItem.status }}
          </v-card-title>

          <v-card-text>
            <v-tabs v-model="tab0" v-if="editedItem.id > 0">
              <v-tabs-slider></v-tabs-slider>
              <v-tab key="1" href="#1"> Test Data </v-tab>
              <v-tab key="2" href="#2"> Events Log </v-tab>
              <v-tab key="3" href="#3">
                Attachments <v-icon> mdi-paperclip </v-icon>
              </v-tab>
            </v-tabs>
            <v-tabs-items v-model="tab0">
              <v-tab-item :value="'1'">
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="2" md="2">
                      <v-autocomplete
                        :items="well_list"
                        v-model="editedItem.well_id"
                        item-value="id"
                        item-text="wellname"
                        label="Well"
                        dense
                        :rules="[(v) => !!v || 'Well required']"
                        @change="well_change"
                        readonly
                      >
                      </v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="2" md="2">
                      <v-select
                        :items="users_exe"
                        v-model="editedItem.techid"
                        item-value="id"
                        item-text="name"
                        label="Tested by"
                        :rules="[(v) => !!v || 'Tested by required']"
                        :readonly="test_readonly || editedItem.statut_id >= 5"
                        dense
                        @change="test_change"
                      >
                      </v-select>
                    </v-col>
                    <v-col cols="12" sm="2" md="2" v-if="editedItem.id > 0">
                      <datepicker
                        label="Test Date"
                        :edit="!test_readonly && editedItem.statut_id < 6"
                        v-model="editedItem.testdate"
                        :rules="[(v) => !!v || 'Test Date required']"
                        :date_max="today"
                        :date_min="editedItem.statut_id != 7 ? date_min : null"
                        :clearable="false"
                        @change="test_change"
                        :key="cs"
                      ></datepicker>
                    </v-col>

                    <v-col cols="12" sm="2" md="2" v-if="editedItem.id > 0">
                      <v-select
                        :items="[
                          { id: 'w', label: 'WorkOver' },
                          { id: 'r', label: 'Rigless' },
                        ]"
                        v-model="editedItem.logmethod"
                        item-value="id"
                        item-text="label"
                        label="Logging Method"
                        :rules="[(v) => !!v || 'Logging Method required']"
                        :readonly="
                          welltubulars_selected.length > 0 ||
                          corrosions_list.length > 0 ||
                          test_readonly
                        "
                        dense
                        @change="test_change"
                      >
                      </v-select>
                    </v-col>
                    <v-col cols="12" sm="2" md="2" v-if="editedItem.id > 0">
                      <v-select
                        :items="tool_list"
                        v-model="editedItem.tool_id"
                        item-value="id"
                        item-text="description"
                        label="Tool"
                        :rules="[(v) => !!v || 'Tool required']"
                        dense
                        :readonly="corrosions_list.length > 0 || test_readonly"
                        @change="tool_change"
                      >
                      </v-select>
                    </v-col>
                    <v-col cols="12" sm="1" md="1" v-if="editedItem.id > 0">
                      <v-text-field
                        autocomplete="off"
                        type="number"
                        dense
                        v-model.number="editedItem.logfrom"
                        :label="'Log from'"
                        :disabled="!editedItem.well_id"
                        hide-spin-buttons
                        :rules="[
                          (v) => v >= 0 || 'Log from required',
                          (v) => v < well.td || 'Max Log from:' + well.td,
                          (v) =>
                            editedItem.logfrom < editedItem.logto ||
                            editedItem.logto == null ||
                            editedItem.logto == '' ||
                            'Error Log from > Log to',
                        ]"
                        :readonly="corrosions_list.length > 0 || test_readonly"
                        @input="test_change"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" sm="1" md="1" v-if="editedItem.id > 0">
                      <v-text-field
                        autocomplete="off"
                        type="number"
                        dense
                        v-model.number="editedItem.logto"
                        :label="'Log to'"
                        :disabled="!editedItem.well_id"
                        :rules="[
                          (v) => v > 0 || 'Log to required',
                          (v) => v <= well.td || 'Max Log to:' + well.td,
                          (v) =>
                            editedItem.logto > editedItem.logfrom ||
                            editedItem.logfrom == null ||
                            editedItem.logfrom == '' ||
                            'Error Log from > Log to',
                        ]"
                        hide-spin-buttons
                        :readonly="corrosions_list.length > 0 || test_readonly"
                        @input="test_change"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="12" v-if="editedItem.id > 0">
                      <v-text-field
                        dense
                        v-model="editedItem.editcomment"
                        label="Comment"
                        @input="test_change"
                        :readonly="test_readonly"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="12" v-if="editedItem.id > 0">
                      <v-select
                        :items="welltubulars"
                        v-model="welltubulars_selected"
                        item-value="id"
                        item-text="label"
                        item-disabled="disabled"
                        label="Tubulars"
                        :rules="[
                          (v) =>
                            welltubulars_selected.length > 0 ||
                            'Tubulars required',
                        ]"
                        outlined
                        dense
                        multiple
                        :disabled="
                          !editedItem.logto ||
                          editedItem.logfrom == null ||
                          !editedItem.well_id ||
                          !editedItem.logmethod
                        "
                        chips
                        :readonly="test_readonly"
                        :key="st"
                        @input="test_change"
                      >
                      </v-select>
                    </v-col>
                  </v-row>
                </v-container>
                <v-container fluid>
                  <v-tabs v-model="tab1" v-if="editedItem.id > 0">
                    <v-tab
                      key="1"
                      href="#1"
                      v-if="welltubulars_selected.length > 0"
                      >Log Detail</v-tab
                    >
                    <v-tab key="2" href="#2" v-if="editedItem.statut_id != 7">
                      Actual Corrosion Log
                      <div style="display: inline-block; position: relative">
                        <v-badge
                          :color="'orange'"
                          v-if="anomalies > 0"
                          overlap
                          bottom
                          right
                        >
                          <template v-slot:badge>{{ anomalies }}</template>
                          <v-icon>mdi-alert</v-icon>
                        </v-badge>
                      </div>
                    </v-tab>
                  </v-tabs>

                  <v-tabs-items v-model="tab1" v-if="editedItem.id > 0">
                    <v-tab-item :value="'1'">
                      <v-form
                        v-if="
                          editedItem.id >= 0 &&
                          (editedItem.statut_id == 4 ||
                            editedItem.statut_id == 5 ||
                            editedItem.statut_id == 8) &&
                          welltubulars_selected.length > 0
                        "
                        ref="ref_cor"
                        lazy-validation
                      >
                        <v-container>
                          <v-row v-if="!test_readonly">
                            <v-col cols="12" sm="3" md="3">
                              <v-autocomplete
                                :items="tubulars_sizes"
                                v-model="corrosion.well_tubular_size_id"
                                item-value="id"
                                item-text="label"
                                label="sizes"
                                :rules="[(v) => !!v || 'String required']"
                                outlined
                                dense
                                @input="cr_changed"
                                @change="string_change"
                                return-object
                              >
                                <template v-slot:selection="{ item }">
                                  {{ item.label + " (" + item.item + ")" }}
                                </template>
                                <template v-slot:item="{ item }">
                                  <v-list-item-content>
                                    {{ item.label + " (" + item.item + ")" }}
                                    <v-list-item-subtitle>
                                      {{
                                        "Depth From " +
                                        numberWithSpace(item.depthfrom) +
                                        " " +
                                        depth_unit
                                      }}
                                    </v-list-item-subtitle>
                                    <v-list-item-subtitle>
                                      {{
                                        "Depth To " +
                                        numberWithSpace(
                                          item.depthfrom != null
                                            ? item.depthto
                                            : 0
                                        ) +
                                        " " +
                                        depth_unit
                                      }}
                                    </v-list-item-subtitle>
                                  </v-list-item-content>
                                </template>
                              </v-autocomplete>
                            </v-col>
                            <v-col cols="12" sm="2" md="2">
                              <v-text-field
                                autocomplete="off"
                                type="number"
                                outlined
                                dense
                                ref="cor_from"
                                v-model.number="corrosion.depthfrom"
                                :label="'Depth From (' + depth_unit + ')'"
                                :rules="[
                                  (v) => v != null || 'Depth From required',
                                  (v) =>
                                    v >= depth_min ||
                                    'Error Min :' + numberWithSpace(depth_min),
                                  (v) =>
                                    v < depth_max ||
                                    'Error Max : ' + numberWithSpace(depth_max),
                                  (v) =>
                                    v < corrosion.depthto ||
                                    !corrosion.depthto ||
                                    'Depth To <= Depth From !!',
                                  (v) =>
                                    (v >= 0 && verif_df) ||
                                    'Depth From exist !!',
                                ]"
                                :disabled="!corrosion.well_tubular_size_id"
                                @input="cr_changed"
                                @focus="$event.target.select()"
                                hide-spin-buttons
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="2" md="2">
                              <v-text-field
                                autocomplete="off"
                                type="number"
                                outlined
                                dense
                                v-model.number="corrosion.depthto"
                                :label="'Depth To (' + depth_unit + ')'"
                                :rules="[
                                  (v) => !!v || 'Depth To required',
                                  (v) =>
                                    v > depth_min ||
                                    'Error Min :' + numberWithSpace(depth_min),
                                  (v) =>
                                    v <= depth_max ||
                                    'Error Max : ' + numberWithSpace(depth_max),
                                  (v) =>
                                    v > corrosion.depthfrom ||
                                    corrosion.depthfrom == null ||
                                    'Depth To <= Depth From !!',
                                ]"
                                :disabled="
                                  !corrosion.well_tubular_size_id ||
                                  corrosion.depthfrom == null
                                "
                                @input="cr_changed"
                                @focus="$event.target.select()"
                                hide-spin-buttons
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="1" md="1">
                              <v-text-field
                                autocomplete="off"
                                type="number"
                                outlined
                                dense
                                v-model.number="corrosion.evaluation"
                                :label="'Evaluation (%)'"
                                :rules="[
                                  (v) =>
                                    !!v ||
                                    corrosion.default_id > 0 ||
                                    'Evaluation required',
                                  (v) =>
                                    corrosion.default_id > 0 ||
                                    (v > 0 && v <= 100) ||
                                    'Value error (Between 0 and 100) ',
                                ]"
                                :disabled="
                                  !corrosion.well_tubular_size_id ||
                                  corrosion.depthfrom == null
                                "
                                v-on:keyup.enter="CorrosionSave"
                                @focus="$event.target.select()"
                                @input="evaluation_change"
                                hide-spin-buttons
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="2" md="2">
                              <v-select
                                :items="defaults_list"
                                v-model="corrosion.default_id"
                                item-value="id"
                                item-text="label"
                                label="Default"
                                :rules="[
                                  (v) =>
                                    !!v ||
                                    corrosion.evaluation > 0 ||
                                    'Default required',
                                ]"
                                outlined
                                dense
                                :disabled="
                                  !corrosion.well_tubular_size_id ||
                                  corrosion.depthfrom == null ||
                                  corrosion.depthto == null
                                "
                                @change="defaultchange"
                                @keydown.enter="CorrosionSave"
                              >
                              </v-select>
                            </v-col>
                            <v-col cols="12" sm="2" md="2" v-if="false">
                              <v-select
                                :items="level_list"
                                v-model="corrosion.level_id"
                                item-value="id"
                                item-text="label"
                                label="level"
                                :rules="[(v) => !!v || 'Severity required']"
                                outlined
                                dense
                                :disabled="
                                  !corrosion.well_tubular_size_id ||
                                  corrosion.depthfrom == null ||
                                  corrosion.depthto == null ||
                                  hasLevel == 1
                                "
                                @change="levelchange"
                                return-object
                                @keydown.enter="CorrosionSave"
                              >
                              </v-select>
                            </v-col>
                            <v-col cols="12" sm="2" md="2">
                              <v-spacer></v-spacer>
                              <v-btn
                                color="blue darken-1"
                                @click.stop="CorrosionSave"
                                class="ma-1"
                                :disabled="
                                  corrosion.well_tubular_size_id == null ||
                                  corrosion.depthfrom == null ||
                                  corrosion.depthto == null ||
                                  (corrosion.evaluation == null &&
                                    corrosion.default_id == null) ||
                                  change_cr == 0
                                "
                              >
                                Save
                              </v-btn>

                              <v-btn
                                color="blue darken-1"
                                @click.stop="cancel"
                                :disabled="
                                  corrosion.well_tubular_size_id == null &&
                                  corrosion.depthfrom == null &&
                                  corrosion.depthto == null &&
                                  corrosion.evaluation == null &&
                                  corrosion.default_id == null &&
                                  corrosion.level_id == null
                                "
                              >
                                Cancel
                              </v-btn>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-form>
                      <v-row>
                        <v-col cols="12" sm="12" md="12">
                          <listitems
                            :list="corrosions_list"
                            :title="'Detail Corrosions'"
                            :headers="corrosions_headers"
                            :showstd="false"
                            :showedit="false"
                            :master="true"
                            :key="cor_key"
                            :ipg="-1"
                            :hdf="true"
                            :add="false"
                            :del="false"
                            :btn_update="!test_readonly"
                            @btn_update_click="OpenImportForm"
                            @col_btn1_click="CorrosionOpen"
                            @col_btn2_click="element_delete"
                          >
                          </listitems>
                        </v-col>
                      </v-row>
                    </v-tab-item>
                    <v-tab-item :value="'2'">
                      <v-row>
                        <v-col cols="12" sm="6" md="6">
                          <listitems
                            :list="tubulars_list"
                            :title="'Casings'"
                            :headers="tubulars_headers"
                            :showstd="false"
                            :showedit="false"
                            :master="true"
                            :key="cas_key"
                            @rowselect="TubularSelect"
                            :ipg="-1"
                            :hdf="true"
                            :add="false"
                            :del="false"
                          >
                          </listitems>
                        </v-col>
                        <v-col cols="12" sm="6" md="6" v-if="tubular.id">
                          <v-row>
                            <v-col cols="12" sd="12" md="12">
                              <listitems
                                :list="tubular.last_corrosions"
                                :title="'Corrosion'"
                                :headers="corrosions_headers2"
                                :showstd="false"
                                :showedit="false"
                                :master="false"
                                :key="tc_key"
                                :ipg="-1"
                                :hdf="true"
                                :add="false"
                                :del="false"
                              >
                              </listitems>
                            </v-col>
                            <v-col cols="12" sd="12" md="12">
                              <v-radio-group
                                v-model="tubular.decision"
                                row
                                mandatory
                                :label="'Data Corrosion for ' + tubular.item"
                                dense
                                v-if="tubular.anomalies > 0"
                              >
                                <v-radio label="Anomalies" :value="0"></v-radio>
                                <v-radio label="Update" :value="1"></v-radio>
                                <v-radio label="Maintain" :value="-1"></v-radio>
                              </v-radio-group>
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-tab-item>
                  </v-tabs-items>
                </v-container>
              </v-tab-item>
              <v-tab-item :value="'2'">
                <listitems
                  :list="events"
                  :headers="events_header"
                  :toolbar="false"
                  :key="kev"
                >
                </listitems>
              </v-tab-item>
              <v-tab-item :value="'3'">
                <filelist
                  :item="editedItem"
                  :editer="true"
                  :doc_type="2"
                  :isform="false"
                  :auth="'01007'"
                  :well_id="parseInt(editedItem.well_id)"
                >
                </filelist
              ></v-tab-item>
            </v-tabs-items>
          </v-card-text>
          <v-card-actions>
            <v-row>
              <v-col cols="12" sd="6" md="6">
                <v-alert v-model="alert" :type="type_alert" dismissible>
                  {{ message }}
                </v-alert>
              </v-col>
              <v-col cols="12" sd="6" md="6">
                <Test_Accept
                  :test="editedItem"
                  :key="ta"
                  @close="close"
                  @test_save="test_save"
                  @status_change="status_change"
                  @validate_test="validate_test"
                  @accept_test="accept_test"
                  :testype_id="2"
                  :changes="changes"
                  :save_disable="save_disable"
                  :test_ok="true"
                  :invalid_test_tubulars="
                    tubulars_list
                      .filter((elm) => elm.decision == -1)
                      .map((elm) => elm.id)
                  "
                ></Test_Accept>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-form>
    <confirmdialog ref="confirm" />
    <v-snackbar
      v-model="snackbar"
      :timeout="snackbar_timeout"
      top
      :color="snackbar_color"
    >
      {{ snackbar_text }}
    </v-snackbar>
    <import_data
      :showForm="!isListClose"
      :title="title2"
      :listheaders="listheaders"
      :items="import_list"
      :item_key="'id'"
      :col_key="'Corrosion Name'"
      :item_id="'id'"
      :width="width"
      :alert="alert"
      :type_alert="type_alert"
      :message="message"
      @close="isListClose = true"
      @save_import="save_import"
      :init_list="init_list"
      :key="frml"
    ></import_data>
  </div>
</template>

<script>
function checkRangeCompliance(arr1, arr2) {
  arr1.forEach((item) => {
    let start = item.depthfrom;
    let complies = true;

    while (start < item.depthto) {
      const matchingRange = arr2.find(
        (range) =>
          range.depthfrom <= start &&
          range.depthto > start &&
          range.evaluation >= item.evaluation
      );

      if (!matchingRange) {
        complies = false;
        break;
      }

      // Move start to the end of the current matching range or the current item’s depthto, whichever is smaller
      start = Math.min(matchingRange.depthto, item.depthto);
    }

    item.complies = complies && start === item.depthto ? 0 : 1;
  });

  return arr1;
}
import CREATE_CORROSION from "../graphql/Well/CREATE_CORROSION.gql";
import UPDATE_CORROSION from "../graphql/Well/UPDATE_CORROSION.gql";
import DELETE_CORROSION from "../graphql/Well/DELETE_CORROSION.gql";
import UPSERT_TEST from "../graphql/WellTest/CCL/UPSERT_TEST.gql";
import CORROSION_IMPORT from "../graphql/WellTest/CCL/CORROSION_IMPORT.gql";
export default {
  components: {
    listitems: () => import("../components/ListItems.vue"),
    datepicker: () => import("../components/DatePicker.vue"),
    confirmdialog: () => import("../components/ConfirmDialog.vue"),
    import_data: () => import("../components/ImportData.vue"),
    Test_Accept: () => import("../components/Test_Accept.vue"),
    filelist: () => import("./FileList.vue"),
  },
  props: {
    showForm: Boolean,
    tool_list: Array,
    level_list: Array,
    defaults_list: Array,
    well_list: Array,
    tubulars_list: Array,
    actual_corrosions: Array,
    item: Object,
    user_list: Array,
    depth_unit: String,
    last_corrosion: Array,
    editer: Boolean,
    valider: Boolean,
  },
  data() {
    return {
      test_readonly: false,
      save_disable: false,
      tool: {},
      init_list: [],
      isListClose: true,
      width: "1000px",
      alert: false,
      type_alert: "info",
      message: "",
      frml: 600,
      listheaders: [],
      import_list: [],
      title2: "",
      snackbar: false,
      snackbar_timeout: 2000,
      snackbar_text: "",
      snackbar_color: "primary",
      well: {},
      showtubulars: false,
      changes: 0,
      hasLevel: 0,
      change_cr: 0,
      depth_min: 0,
      depth_max: 0,
      operation: "Add",
      cs: 100,
      severity: "",
      isCorrosionClose: true,
      loading: false,
      editedItem: {},
      corrosion: {},
      title: "",
      cor_key: 500,
      ta: 150,
      corrosions_list: [],
      welltubulars_selected: [],
      st: 0,
      events: [],
      events_header: [
        {
          value: "id",
          selected: false,
        },
        {
          text: "Date",
          value: "event_date",
          selected: true,
        },
        {
          text: "Operation",
          value: "statut",
          selected: true,
        },
        {
          text: "By",
          value: "operator",
          selected: true,
        },
        {
          text: "Comment",
          value: "comment",
          selected: true,
        },
      ],
      kev: 160,
      tubular: {},
      cas_key: 1500,
      tc_key: 1600,
      tab0: "1",
      tab1: "1",
      corrosions_headers: [
        {
          text: "No",
          value: "no",
          selected: true,
        },
        {
          text: "Id",
          value: "id",
        },
        {
          text: "Id2",
          value: "well_tubular_size_id",
        },
        {
          text: "String",
          value: "sizes",
          selected: true,
          import: true,
        },

        {
          text: "Depth From",
          value: "depthfrom",
          selected: true,
          slot: "cur33",
          align: "end",
          import: true,
        },
        {
          text: "Depth To",
          value: "depthto",
          selected: true,
          slot: "cur33",
          align: "end",
          import: true,
        },
        {
          text: "Depth",
          value: "depth",
          selected: true,
          slot: "cur33",
          align: "end",
        },
        {
          text: "Evaluation",
          value: "evaluation",
          slot: "progress",
          selected: true,
          import: true,
          color: "color",
        },
        {
          text: "Level",
          value: "level",
          import: true,
          selected: true,
        },
        {
          text: "Default",
          value: "default_name",
          import: true,
          selected: true,
        },
        {
          text: "Update",
          slot: "col_btn1",
          selected: true,
          icon: "mdi-pencil",
          width: "10",
          sortable: false,
        },
        {
          text: "Delete",
          slot: "col_btn2",
          selected: true,
          icon: "mdi-delete",
          width: "10",
          sortable: false,
        },
      ],
      corrosions_headers2: [
        {
          text: "No",
          value: "no",
          selected: true,
        },
        {
          text: "Depth From",
          value: "depthfrom",
          selected: true,
          slot: "cur33",
          align: "end",
          import: true,
        },
        {
          text: "Depth To",
          value: "depthto",
          selected: true,
          slot: "cur33",
          align: "end",
          import: true,
        },
        {
          text: "Depth",
          value: "depth",
          selected: true,
          slot: "cur33",
          align: "end",
        },
        {
          text: "Evaluation",
          value: "evaluation",
          slot: "progress",
          selected: true,
          color: "color",
        },
        {
          text: "Level",
          value: "level",
          selected: true,
        },
        {
          text: "Default",
          value: "default_name",
          selected: true,
        },
        {
          text: "Anomalie",
          slot: "col_btn1",
          selected: true,
          icon: "mdi-alert",
          column: "complies",
          valeur: 1,
          color: "orange",
          sortable: false,
          tooltip:
            "Evaluation of the interval or part of the interval, not found or smaller",
        },
      ],
      anomalies: 0,
      tubulars_headers: [
        {
          text: "Position",
          value: "no",
          selected: true,
          sortable: false,
          index: 0,
        },

        {
          text: "Item",
          value: "item",
          selected: true,
          sortable: false,
          index: 0,
        },
        {
          text: "Size (in)",
          value: "api_in",
          selected: true,
          slot: "text_icon",
          icon: "mdi-dots-horizontal",
          column: "nbsizes",
          valeur: "1",
          width: "250",
          sortable: false,
          index: 0,
        },
        {
          text: "Age (Year)",
          value: "age",
          selected: true,
          sortable: false,
          index: 0,
        },

        {
          text: "Anomalies",
          value: "anomalies",
          selected: true,
          sortable: false,
        },
      ],
    };
  },
  mounted() {
    if (this.item) {
      if (this.item.id > 0) {
        this.editedItem = Object.assign({}, this.item);
        this.cs++;
        this.corrosions_headers[10].hiden =
          this.editedItem.statut_id == 6 || this.editedItem.statut_id == 7;
        this.corrosions_headers[11].hiden =
          this.editedItem.statut_id == 6 || this.editedItem.statut_id == 7;
        this.events = this.item.events;
        this.well_change();
        this.tool_change();
        if (this.editedItem.wellstest_tubulars) {
          this.welltubulars_selected = this.editedItem.wellstest_tubulars.map(
            (elm) => elm.well_tubular_id
          );
          this.welltubulars_selected.sort();
        }
        if (this.editedItem.corrosion_details)
          this.corrosions_list = this.editedItem.corrosion_details;
        this.cor_key++;
        this.init_last_corrosion();
      } else {
        this.editedItem = Object.assign({}, { id: -1, statut_id: 4 });
        if (this.well_list)
          if (this.well_list.length == 1) {
            this.editedItem.well_id = this.well_list[0].id;
            this.well_change();
          }
      }
      this.get_status();
      this.ta++;
    }
    this.$nextTick(() => {
      this.changes = 1;
    });
  },
  computed: {
    users_exe() {
      let l = [];
      if (this.user_list)
        l = this.user_list.filter((elm) => elm.autorisations.includes("06003"));
      return l;
    },
    users_eval() {
      let l = [];
      if (this.user_list)
        l = this.user_list.filter((elm) => elm.autorisations.includes("07003"));
      return l;
    },
    drillingdate() {
      return this.well ? this.well.drillingdate : this.$store.state.today;
    },
    today() {
      return this.$store.state.today;
    },
    verif_df() {
      let i = this.corrosions_list.findIndex(
        (elm) =>
          this.corrosion.id != elm.id &&
          this.corrosion.well_tubular_id == elm.well_tubular_id &&
          this.corrosion.well_tubular_size_id == elm.well_tubular_size_id &&
          ((this.corrosion.depthfrom >= elm.depthfrom &&
            this.corrosion.depthfrom < elm.depthto) ||
            (elm.depthfrom >= this.corrosion.depthfrom &&
              elm.depthfrom < this.corrosion.depthto))
      );
      return i == -1;
    },
    verif_dt() {
      let i = this.corrosions_list.findIndex(
        (elm) =>
          this.corrosion.id != elm.id &&
          this.corrosion.well_tubular_id == elm.well_tubular_id &&
          this.corrosion.well_tubular_size_id == elm.well_tubular_size_id &&
          ((this.corrosion.depthfrom > elm.depthfrom &&
            this.corrosion.depthfrom <= elm.depthto) ||
            (elm.depthfrom > this.corrosion.depthfrom &&
              elm.depthfrom <= this.corrosion.depthto))
      );
      return i == -1;
    },
    date_min() {
      let d = this.drillingdate;
      if (this.actual_corrosions) {
        let i = this.actual_corrosions.findIndex(
          (elm) => elm.well_id == this.editedItem.well_id
        );
        if (i >= 0) {
          this.actual_corrosions[i].tubulars.forEach((element) => {
            if (element.logRanges)
              element.logRanges.forEach((element2) => {
                if (d == null || d < element2.testdate) d = element2.testdate;
              });
          });
        }
      }
      if (d) {
        const todayDate = new Date(d);
        todayDate.setDate(todayDate.getDate() + 1);
        d = todayDate.toISOString().slice(0, 10);
      }
      return d;
    },
    tubulars_sizes() {
      let l = [];
      if (this.tubulars_list) {
        this.tubulars_list
          .filter(
            (elm) =>
              elm.well_id == this.editedItem.well_id &&
              this.welltubulars_selected.includes(elm.id)
          )
          .forEach((element) => {
            element.sizes_list.forEach((element2) => {
              l.push(element2);
            });
          });
      }

      return l;
    },

    welltubulars() {
      let l = [];
      let l2 = [];
      if (this.tubulars_list)
        l = this.tubulars_list.filter(
          (elm) =>
            elm.well_id == this.editedItem.well_id &&
            ((elm.depthfrom >= this.editedItem.logfrom &&
              elm.depthfrom <= this.editedItem.logto) ||
              (this.editedItem.logfrom >= elm.depthfrom &&
                this.editedItem.logfrom <= elm.depthto)) &&
            (this.editedItem.logmethod == "w" ? elm.tubular_id != 1 : true)
        );
      l.sort(this.sortBy("csg_order"));

      for (let index = 0; index < l.length; index++) {
        const element = l[index];
        element.disabled = false;
        let i = this.welltubulars_selected.findIndex(
          (elm) => elm == element.id
        );
        if (i >= 0) {
          //see the next
          let j = l.findIndex(
            (elm) =>
              elm.csg_order == element.csg_order + 1 &&
              this.welltubulars_selected.includes(elm.id)
          );
          let k = this.corrosions_list.findIndex(
            (elm) => elm.well_tubular_id == element.id
          );
          element.disabled = j >= 0 || k >= 0;

          l2.push(element);
        } else {
          l2.push(element);
          break;
        }
      }

      return l2;
    },
  },
  watch: {},
  methods: {
    get_status() {
      this.test_readonly =
        (this.editedItem.statut_id != 4 &&
          this.editedItem.statut_id != 5 &&
          this.editedItem.statut_id != 8) ||
        !this.editer;

      if (this.editedItem.statut_id == 4) this.editedItem.status = "Attributed";
      if (this.editedItem.statut_id == 5)
        this.editedItem.status = "In Progress";
      if (this.editedItem.statut_id == 6) this.editedItem.status = "Completed";
      if (this.editedItem.statut_id == 7) this.editedItem.status = "Accepted";
      if (this.editedItem.statut_id == 8) this.editedItem.status = "Rejected";
      this.corrosions_headers[10].hiden =
        this.editedItem.statut_id == 6 || this.editedItem.statut_id == 7;
      this.corrosions_headers[11].hiden =
        this.editedItem.statut_id == 6 || this.editedItem.statut_id == 7;
    },
    status_change(status) {
      this.editedItem.statut_id = status;
      this.get_status();
      this.$emit("update", this.editedItem);
      this.$emit("change", this.editedItem);
    },
    verif_df_list(list, cor) {
      let i = list.findIndex(
        (elm) =>
          cor.id != elm.id &&
          cor.well_tubular_id == elm.well_tubular_id &&
          cor.well_tubular_size_id == elm.well_tubular_size_id &&
          ((cor.depthfrom >= elm.depthfrom && cor.depthfrom < elm.depthto) ||
            (elm.depthfrom > cor.depthfrom && elm.depthfrom < cor.depthto))
      );
      if (i == -1)
        i = this.corrosions_list.findIndex(
          (elm) =>
            cor.id != elm.id &&
            cor.well_tubular_id == elm.well_tubular_id &&
            cor.well_tubular_size_id == elm.well_tubular_size_id &&
            ((cor.depthfrom >= elm.depthfrom && cor.depthfrom < elm.depthto) ||
              (elm.depthfrom > cor.depthfrom && elm.depthfrom < cor.depthto))
        );
      return i == -1;
    },
    verif_dt_list(list, cor) {
      let i = list.findIndex(
        (elm) =>
          cor.id != elm.id &&
          cor.well_tubular_id == elm.well_tubular_id &&
          cor.well_tubular_size_id == elm.well_tubular_size_id &&
          ((cor.depthfrom > elm.depthfrom && cor.depthfrom < elm.depthto) ||
            (elm.depthfrom > cor.depthfrom && elm.depthfrom < cor.depthto))
      );
      if (i == -1)
        i = this.corrosions_list.findIndex(
          (elm) =>
            cor.id != elm.id &&
            cor.well_tubular_id == elm.well_tubular_id &&
            cor.well_tubular_size_id == elm.well_tubular_size_id &&
            ((cor.depthfrom > elm.depthfrom && cor.depthfrom < elm.depthto) ||
              (elm.depthfrom > cor.depthfrom && elm.depthfrom < cor.depthto))
        );
      return i == -1;
    },
    sortBy() {
      return function (a, b) {
        if (a < b) return 1;
        if (a > b) return -1;
        return 0;
      };
    },
    OpenImportForm() {
      this.alert = false;
      this.init_list = [];
      this.form_list = [];
      this.title2 = "Import Corrosions data";
      this.corrosions_headers[8].import = this.tool.tooldetails.length == 0;
      this.listheaders = this.corrosions_headers.filter(
        (elm) => elm.import == true
      );

      this.tubulars_sizes.forEach((el) => {
        this.init_list.push({
          id: el.id,
          sizes: el.label + " (" + el.item + ")",
        });
      });

      this.width = "1000px";
      this.orientation = "p";
      this.isListClose = false;
      this.imp_si = true;
      this.frml++;
    },
    init_last_corrosion() {
      this.tubulars_list.forEach((element) => {
        element.last_corrosions = [];
        element.anomalies = 0;
        if (!element.decision) element.decision = 0;
        let i = this.actual_corrosions.findIndex(
          (elm) => elm.well_id == this.editedItem.well_id
        );
        if (i >= 0) {
          let j = this.actual_corrosions[i].tubulars.findIndex(
            (elm) => elm.well_tubular_id == element.id
          );
          if (j >= 0)
            element.last_corrosions =
              this.actual_corrosions[i].tubulars[j].corrosionRanges;
        }
      });
    },
    verif_last_corrosion() {
      this.alert = false;
      this.init_last_corrosion();
      this.tubulars_list
        .filter((elm) => this.welltubulars_selected.includes(elm.id))
        .forEach((element) => {
          element.last_corrosions = checkRangeCompliance(
            element.last_corrosions,
            this.corrosions_list
          );
        });
      this.tubulars_list
        .filter((elm) => this.welltubulars_selected.includes(elm.id))
        .forEach((element) => {
          element.anomalies = element.last_corrosions.reduce(
            (a, b) => a + (b["complies"] || 0),
            0
          );
        });
      this.anomalies = this.tubulars_list.reduce(
        (a, b) => a + (b["anomalies"] || 0),
        0
      );

      this.cas_key++;
      let l = this.tubulars_list.filter((elm) =>
        this.welltubulars_selected.includes(elm.id)
      );

      let i = l.findIndex((elm2) => elm2.anomalies > 0 && elm2.decision == 0);
      if (i >= 0) {
        this.message = "Anomalies must be resolved before validating test!";
        this.type_alert = "error";
        this.alert = true;
      }
      return i == -1;
    },
    TubularSelect(item) {
      if (item) {
        this.tubular = item;
        this.tc_key++;
      }
    },
    async save_import(items) {
      let ok = true;
      this.message = null;
      let list = [];
      let verif = true;
      for (let index = 0; index < items.length; index++) {
        const element = items[index];

        if (element.depthfrom == null || typeof element.depthfrom != "number") {
          verif = false;
          ok = false;
          this.message =
            "Invalid depthfrom value Number" + " - ligne " + (index + 1);
          this.type_alert = "error";
          this.alert = true;
          break;
        }
        if (
          (element.depthto == null || typeof element.depthto != "number") &&
          ok
        ) {
          verif = false;
          ok = false;
          this.message =
            "Invalid depthto value Number" + " - ligne " + (index + 1);
          this.type_alert = "error";
          this.alert = true;
          break;
        }
        if (
          (element.evaluation == null ||
            typeof element.evaluation != "number" ||
            element.evaluation < 0 ||
            element.evaluation > 100) &&
          ok
        ) {
          verif = false;
          ok = false;
          this.message =
            "Invalid evaluation value Number" + " - ligne " + (index + 1);
          this.type_alert = "error";
          this.alert = true;
          break;
        }
        if (
          (element.level == null ||
            typeof element.level != "number" ||
            ![1, 2, 3, 4].includes(element.level)) &&
          this.tool.tooldetails.length == 0 &&
          ok
        ) {
          verif = false;
          ok = false;
          this.message = "Invalid level value" + " - ligne " + (index + 1);
          this.type_alert = "error";
          this.alert = true;
          break;
        }
        let k = this.tubulars_sizes.findIndex(
          (x) => x.label.trim() + " (" + x.item + ")" === element.sizes.trim()
        );
        if (k < 0) {
          verif = false;
          ok = false;
          this.message = "Invalid casing value" + " - ligne " + (index + 1);
          this.type_alert = "error";
          this.alert = true;
          break;
        } else {
          let t = this.tubulars_sizes[k];
          element.well_tubular_size_id = t.id;
          element.well_tubular_id = t.well_tubular_id;
          if (
            element.depthfrom >= element.depthto ||
            element.depthfrom < t.depthfrom ||
            element.depthto > t.depthto
          ) {
            verif = false;
            ok = false;
            this.message = "Invalid value depth" + " - ligne " + (index + 1);
            this.type_alert = "error";
            this.alert = true;
            break;
          }
        }
        if (ok) {
          if (!this.verif_df_list(items, element)) {
            verif = false;
            ok = false;
            this.message = "Depth From exist" + " - ligne " + (index + 1);
            this.type_alert = "error";
            this.alert = true;
            break;
          }
          if (!this.verif_dt_list(items, element)) {
            verif = false;
            ok = false;
            this.message = "Depth To exist" + " - ligne " + (index + 1);
            this.type_alert = "error";
            this.alert = true;
            break;
          }
        }

        if (verif) {
          let level = element.level;
          if (this.editedItem.tool_id) {
            let k = this.tool_list.findIndex(
              (t) => t.id == this.editedItem.tool_id
            );
            if (k >= 0) {
              let l = this.tool_list[k].tooldetails.findIndex(
                (x) =>
                  element.evaluation >= x.ml_from &&
                  element.evaluation <= x.ml_to
              );
              if (l >= 0) level = this.tool_list[k].tooldetails[l].level_id;
            }
          }
          list.push({
            depthfrom: element.depthfrom,
            depthto: element.depthto,
            evaluation: element.evaluation,
            level_id: level,
            well_id: this.editedItem.well_id,
            welltest_id: this.editedItem.id,
            well_tubular_size_id: element.well_tubular_size_id,
            well_tubular_id: element.well_tubular_id,
          });
        }
      }
      if (ok) {
        if (
          await this.$refs.confirm.open(
            "Confirmation",
            "Are you sûr to update corrosions data?",
            { color: "orange darken-3" }
          )
        ) {
          let v = {
            list: list,
          };
          let r = await this.$maj(CORROSION_IMPORT, v);
          if (r.ok) {
            //----------
            this.corrosions_list = JSON.parse(r.data.ImportCorrosions);
            this.isListClose = true;
            this.cor_key++;
            //------------
          } else {
            this.snackbar_text = "Saving Error";
            this.snackbar_color = "error";
            this.snackbar = true;
          }
        }
      }
    },

    well_change() {
      this.alert = false;
      this.well = null;
      this.welltubulars_selected = [];
      this.$refs.form.resetValidation();
      let i = this.well_list.findIndex(
        (elm) => elm.id == this.editedItem.well_id
      );
      if (i >= 0) {
        this.well = this.well_list[i];
      }
      this.changes++;
    },
    tool_change() {
      this.alert = false;
      this.tool = null;
      if (this.tool_list) {
        let i = this.tool_list.findIndex(
          (elm) => elm.id == this.editedItem.tool_id
        );
        if (i >= 0) {
          this.tool = this.tool_list[i];
        }
        this.changes++;
      }
    },
    test_change() {
      this.changes++;
    },
    cr_changed() {
      this.change_cr = 1;
    },
    levelchange(item) {
      this.corrosion.level = item.label;
    },
    defaultchange() {
      let i = this.defaults_list.findIndex(
        (elm) => elm.id == this.corrosion.default_id
      );
      if (i >= 0) this.corrosion.default_name = this.defaults_list[i].label;
    },
    evaluation_change() {
      this.alert = false;
      this.change_cr = 1;
      this.hasLevel = 0;
      this.corrosion.level_id = null;

      if (this.editedItem.tool_id) {
        let k = this.tool_list.findIndex(
          (t) => t.id == this.editedItem.tool_id
        );
        if (k >= 0) {
          let l = this.tool_list[k].tooldetails.filter(
            (x) =>
              this.corrosion.evaluation >= x.ml_from &&
              this.corrosion.evaluation <= x.ml_to
          );
          if (l.length > 0) {
            this.corrosion.level_id = l[0].level_id;
            this.corrosion.level = l[0].level;
            this.hasLevel = 1;
          }
        }
      }
    },
    string_change(item) {
      this.alert = false;
      if (item) {
        this.depth_min = item.depthfrom;
        if (this.depth_min < this.editedItem.logfrom)
          this.depth_min = this.editedItem.logfrom;
        this.depth_max = item.depthto;
        if (this.depth_max > this.editedItem.logto)
          this.depth_max = this.editedItem.logto;
        this.corrosion.well_tubular_id = item.well_tubular_id;
        this.corrosion.well_tubular_size_id = item.id;
        this.corrosion.label = item.label;
        this.change_cr = 1;
      }
    },
    async test_save() {
      this.alert = false;
      if (this.$refs.form.validate()) {
        this.save_disable = true;
        this.editedItem.testtype_id = 2;
        let statut_id = this.editedItem.id >= 0 ? 5 : 4;
        let v = {
          test: [
            {
              id: this.editedItem.id >= 0 ? this.editedItem.id : null,
              well_id: this.editedItem.well_id,
              logfrom: this.editedItem.logfrom,
              logto: this.editedItem.logto,
              statut_id: statut_id,
              techid: this.editedItem.techid,
              editcomment: this.editedItem.editcomment,
              testtype_id: this.editedItem.testtype_id,
              testdate: this.editedItem.testdate,
              tool_id: this.editedItem.tool_id,
              logmethod: this.editedItem.logmethod,
            },
          ],
          welltests_tubulars: this.welltubulars_selected,
        };

        let r = await this.$maj(UPSERT_TEST, v);
        if (r.ok) {
          this.editedItem = r.data.UpsertTest;

          this.changes = 1;
          this.$emit("change", this.editedItem);
          this.$refs.form.resetValidation();
          this.get_status();
        }
        this.save_disable = false;
      }
    },
    close() {
      this.$emit("close");
    },
    async element_delete(item) {
      if (
        await this.$refs.confirm.open(
          "Confirmation",
          "Delete this item !!-Are you sur ?",
          { color: "orange darken-3" }
        )
      ) {
        this.data_loading = true;
        let r = await this.$maj(DELETE_CORROSION, { id: item.id });
        if (r) {
          this.corrosions_list.splice(item.index, 1);
          this.$emit("change", this.corrosion);
        } else this.data_loading = false;
      }
    },

    CorrosionOpen(item) {
      this.corrosion = item;
      let i = this.tubulars_sizes.findIndex(
        (elm) => elm.id == item.well_tubular_size_id
      );
      if (i >= 0) this.string_change(this.tubulars_sizes[i]);
    },
    accept_test(callback) {
      callback(this.verif_last_corrosion());
    },
    async validate_test(callback) {
      if (this.corrosions_list.length == 0) return;
      callback(this.$refs.form.validate());
    },
    cancel() {
      if (!this.btn_click) {
        this.btn_click = true;

        this.$refs.ref_cor.resetValidation();
        this.$nextTick(() => {
          this.corrosion = {};
        });

        this.element_edit = false;
        this.btn_click = false;
      }
    },
    async CorrosionSave() {
      if (this.$refs.ref_cor.validate()) {
        this.corrosion.depth =
          this.corrosion.depthto - this.corrosion.depthfrom;
        this.corrosion.expire = this.corrosion.depth < 0;
        this.corrosion.color =
          this.corrosion.evaluation < this.cor_rate ? "orange" : "red";
        let i = this.tubulars_list.findIndex(
          (elm) => elm.id == this.corrosion.well_tubular_id
        );
        if (i >= 0)
          this.corrosion.string =
            this.tubulars_list[i].label +
            " (" +
            this.tubulars_list[i].item +
            ")";
        if (this.corrosion.id > -1) {
          let v = {
            wellscorrosion: {
              id: this.corrosion.id,
              well_id: this.editedItem.well_id,
              welltest_id: this.editedItem.id,
              well_tubular_size_id: this.corrosion.well_tubular_size_id,
              well_tubular_id: this.corrosion.well_tubular_id,
              depthfrom: this.corrosion.depthfrom,
              depthto: this.corrosion.depthto,
              evaluation: this.corrosion.evaluation,
              level_id: this.corrosion.level_id,
              default_id: this.corrosion.default_id,
              write_uid: this.$store.state.me.id,
            },
          };
          let r = await this.$maj(UPDATE_CORROSION, v);
          if (r.ok) {
            this.cor_key++;
            this.corrosion = {};
            this.change_cr = 0;
            this.$emit("change", this.editedItem);
            this.$refs.ref_cor.resetValidation();
          }
        } else {
          let v = {
            wellscorrosion: {
              well_id: this.editedItem.well_id,
              welltest_id: this.editedItem.id,
              well_tubular_size_id: this.corrosion.well_tubular_size_id,
              well_tubular_id: this.corrosion.well_tubular_id,
              level_id: this.corrosion.level_id,
              default_id: this.corrosion.default_id,
              depthfrom: this.corrosion.depthfrom,
              depthto: this.corrosion.depthto,
              evaluation: this.corrosion.evaluation,
              create_uid: this.$store.state.me.id,
            },
          };
          let r = await this.$maj(CREATE_CORROSION, v);
          if (r.ok) {
            this.corrosion.id = r.data.createWellsCorrosion.id;
            let j = this.tubulars_sizes.findIndex(
              (elm) => elm.id == this.corrosion.well_tubular_size_id
            );
            if (j >= 0) this.corrosion.sizes = this.tubulars_sizes[j].label;
            this.corrosions_list.push(this.corrosion);
            this.$emit("change", this.editedItem);
            this.cor_key++;
            let id = this.corrosion.well_tubular_size_id;
            this.change_cr = 0;

            this.corrosion = {
              well_tubular_size_id: id,
              well_tubular_id: this.corrosion.well_tubular_id,
              sizes: this.corrosion.sizes,
              level_id: null,
              default_id: null,
              level: this.corrosion.level,
              depthfrom: null,
              depthto: null,
              evaluation: null,
            };
            this.$refs.ref_cor.resetValidation();
            this.$refs.cor_from.focus();
          }
        }
        this.changes = 1;
      }
    },
    numberWithSpace: function (x) {
      return x
        ? x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, " ")
        : "0";
    },
  },
};
</script>
